<template>
  <div>
    <div class="knives__winners">
      <div class="container">
        <h3 class="knives__winners-title knives__block-title">
          {{ $t("knives.winnersTitle") }}
        </h3>
        <div class="knives__winners-table--box" v-html="getTable"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "KnivesWinners",
  computed: {
    ...mapState("knivesModule", ["promo"]),
    getTable() {
      return this.promo.attributes.conditions;
    },
  },
};
</script>

<style lang="scss" scoped></style>
